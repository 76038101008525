<template>
  <div class="bg-white w-full p-3">
    <section class="w-full h-full">
      <PageHeader
        :heading="$t('xpbx.pages.heading.settings')"
        :showButton="false"
      />
    </section>
  </div>
</template>

<script>
import PageHeader from "@/modules/xpbx/components/home/UI/PageHeader.vue";

export default {
  name: "SettingsPage",

  components: {
    PageHeader,
  },

  setup() {
    return {};
  },

  created() {
    this.$i18n.locale = localStorage.getItem("user_lang");
  },
};
</script>